import React from 'react';
import "./projects.css";
import { MenuComponent } from '../menu';

export const ProjectsComponent = (props) => {   

  return (
    <div>
      <MenuComponent />
      <div className='PageHeaderProjects'>
        EDS Projects &amp; Applications
      </div>
      <div className='Container'>
        <div class="flex">
          <div className='Sections'>
              <div className='SectionHeader'><h2>GIS Projects</h2></div>
              <div className='SectionText'>
                  EDS can provide GIS solutions for any of your biological data needs.  A special site was developed
                  to allow clients to securly view their GIS projects.
              </div>
              <br />
              <div className='SectionFooter'>Coming Soon</div>
          </div>
          <div className='Sections'>
            <div className='SectionHeader'><h2>BioData</h2></div>
            <div className='SectionText'>
                BioData is a an application that collects data in the field through a mobile or tablet device. 
                The data can then be synced back to the main office where it can be administered and reported 
                on through its web site.
                <br /><br />
                This application is currently in development.
            </div>
            <br />
            <div className='SectionFooter'><a href="https://biodata.envdataservices.com/" target='biodata'>biodata.envdataservices.com</a></div>
          </div>
          <div className='Sections'>
            <div className='SectionHeader'><h2>BioTrack</h2></div>
            <div className='SectionText'>
                BioTrack, stores information on an observed species in the state of West Virginia. When available, 
                the water quality data that was collected at the location can be viewed as well as any changes in the 
                population. BioTrack also has the capability to show if the location where the species were observed 
                is in an area where mining has occurred.
            </div>
            <br />
            <div className='SectionFooter'>Coming Soon</div>
          </div>
        </div>
      </div>
    </div>
  );
};