import React from 'react';
import "./contact.css";
import { MenuComponent } from '../menu';

export const ContactComponent = (props) => {   

  return (
    <div>
      <MenuComponent />
      <div className='PageHeaderContact'>
        Contact Environmental Data Services
      </div>
      <div className="PageContent">
        Email Address: <a href="mailto:ashworth.chad@envdataservices.com">ashworth.chad@envdataservices.com</a><br />
        Phone Number: (304)941-5855
      </div>
    </div>
  );
};