import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainComponent } from './components/main';
import { AboutComponent } from './components/about/about';
import { ContactComponent } from './components/contact/contact';
import { ProjectsComponent } from './components/projects/projects';

import 'primereact/resources/themes/nova-alt/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainComponent />} />
      <Route path="/about" element={<AboutComponent />} />
      <Route path="/contact" element={<ContactComponent />} />
      <Route path="/projects" element={<ProjectsComponent />} />
    </Routes>
  </BrowserRouter>
);