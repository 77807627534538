import React from 'react';
import { Menubar } from 'primereact/menubar';
import "./menu.css";

export const MenuComponent = (props) => {   
    const items = [
        {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            url: '/'
        },
        {
            label: 'About',
            icon: 'pi pi-fw pi-question-circle',
            url: '/about'
        },
        {
            label: 'Contact',
            icon: 'pi pi-fw pi-user',
            url: '/contact'
        },
        {
            label: 'Projects',
            icon: 'pi pi-fw pi-cog',
            url: '/projects'
        },
        {
            label: 'Links',
            icon: 'pi pi-fw pi-link',
            url: '/'
        }
    ];

  return (
    <div>
        <Menubar model={items} start="EDS" />
    </div>
    );
};