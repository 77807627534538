export const CarouselService = {
    getCarouselData() {
        return [
            {
                id: '1000',
                color: "#19AD22",
                title: 'Environmental Data Services',
                description: 'Providing Technology Solutions for the Environmental Industry'
            },
            {
                id: '1001',
                color: "#6B5772",
                title: 'BioData',
                description: 'Biological Data Collection Solutions'
            },
            {
                id: '1002',
                color: "#867D43",
                title: 'Geographic Information Systems',
                description: 'GIS Solutions for Biological Data'
            },
            {
                id: '1003',
                color: "#4C6BA2",
                title: 'Data Collection and Analysis',
                description: 'Data Collection and Analytical Services for Biological Data'
            }
        ];
    },

    getCarousel() {
        return Promise.resolve(this.getCarouselData());
    }
};

