import React from 'react';
import { MenuComponent } from '../menu';
import "./about.css";

export const AboutComponent = (props) => {   
  return (
    <div>
      <MenuComponent />
      <div className="PageHeaderAbout">
        About Environmental Data Services
      </div>
      <div className="PageContent">
        Environmental Data Services, EDS, is a West Virginia-based company founded in May of 2017 by Chad Ashworth. He has over 20 years of experience writing software and 9 years of experience in the environmental field.
        <br /><br />
        Since 2017, EDS has worked with Dr. Scott Simonton of Ashby-Tucker and Neely & Callaghan Law Firm, and among other companies and law firms to provide GIS solutions and various other technical services as related to the environmental field.
        <br /><br />
        In 2024, EDS will release Phase One of BioData. BioData is an application that collects data in the field through a mobile or tablet device.
      </div>
    </div>
  );
};