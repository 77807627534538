import React from 'react';
import "./main.css";
import { MenuComponent } from '../menu';
import { CarouselComponent } from '../carousel/carousel';

export const MainComponent = (props) => {   

  return (
    <div>
        <MenuComponent />
        <CarouselComponent />
        <div className="Container">
          <div className="InfoSection">
            <div className='CircleStyle'>
              <span className="CircleIcon">
                <span className="pi pi-tablet" style={{ fontSize: '4rem' }}></span>
              </span>
            </div>
            <span>
              <span style={{textAlign: "center"}}><h2>BioData</h2></span>
              <p>
                BioData is an application that collects field data for desired state and federal forms.
              </p>
            </span>
          </div>
          <div className="InfoSection">
            <div className='CircleStyle'>
              <span className="CircleIcon">
                <span className="pi pi-globe" style={{ fontSize: '4rem' }}></span>
              </span>
            </div>
            <span>
              <span style={{textAlign: "center"}}><h2>GIS</h2></span>
              <p>
              EDS can provide GIS solutions for any of your biological data needs.
              </p>
            </span>
          </div>
          <div className="InfoSection">
            <div className='CircleStyle'>
              <span className="CircleIcon">
                <span className="pi pi-database" style={{ fontSize: '4rem' }}></span>
              </span>
            </div>
            <span>
              <span style={{textAlign: "center"}}><h2>Data</h2></span>
              <p>
              More information about this service coming soon.
              </p>
            </span>
          </div>
        </div>
    </div>
  );
};