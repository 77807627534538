import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { CarouselService } from '../../services/CarouselService';
import "./carousel.css";

export const CarouselComponent = (props) => {   
    const [CarouselData, setCarouselData] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        CarouselService.getCarousel().then(data => {
            setCarouselData(data);
        });
    }, []);

    const carouselTemplate = (CarouselData) => {
        return (
            <div>
                <div className="CarouselMain">
                    <span className="CarouselTitle">{CarouselData.title}</span><br /><br />
                    <span className="CarouselSubTitle">{CarouselData.description}</span>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Carousel value={CarouselData} autoplayInterval={6000} itemTemplate={carouselTemplate} />
        </div>
    );
};